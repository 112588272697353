<template>
    <!-- <router-link :to="{name: 'campaign.show', params:{slug: slug}}"> -->
        <div class="bg-white rounded-xl shadow-md my-4 mx-2">
            <div class="rounded-xl md:p-0">
                <img class="h-36 w-64 rounded-tl-xl rounded-tr-xl object-cover" :src="image">
                <div class=" w-64 pt-2 md:pt-5 p-4 md:p-3 text-left space-y-2">
                    <p class="text-sm font-semibold title">{{ title }}</p>

                        <p class="text-xs text-gray-500 mb-3">
                            <span class="font-bold text-yellow-600">{{ formattedDate(dateEbook) }} </span>
                        </p>
                        <button @click="downloadPDF(pdf)"
                            class="bg-yellow-500 py-1.5 rounded-md shadow-md text-sm w-full uppercase 
                            font-bold text-white focus:outline-none focus:bg-yellow-600">Download
                        </button>
                        <!-- <a class="btn-download" :href="pdf" target="_blank" download>
                                    Download
                                </a> -->
                        <!-- <div class="relative">
                            <div class="overflow-hidden mb-1 flex rounded bg-yellow-200">
                                <div :style="{width: percentage(donation.total, targetDonation) + '%'}"
                                    class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-500">
                                </div>
                                
                            </div>
                        </div> -->
                        <!-- bg-yellow-500 py-1.5 rounded-md shadow-md text-lg w-full uppercase font-bold text-white focus:outline-none focus:bg-yellow-600 -->
                    
                    <!-- <div class="mt-1 text-xs">
						<strong>{{ countDay(maxDate) }}</strong> hari lagi
					</div> -->
                </div>
            </div>
        </div>
    <!-- </router-link> -->
</template>

<script>
    import CampaignTypeEnum from '@/enums/CampaignTypeEnum.js'

    export default {
        props: {
            image: String,
            title: String,
            pdf: String,
            dateEbook: Date
        },
        methods: {
        downloadPDF(val) {
            const pdfUrl = val; // Replace with the actual path to your PDF
            const link = document.createElement('a');
            link.target = '_blank';
            link.href = pdfUrl;
            link.download = 'ebook.pdf'; // The name the file will be saved as
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            }
        },
        setup() {
            const campaignType = CampaignTypeEnum
            const formattedDate = (dateEbook) => {
                if (!dateEbook) return ''; // Jika tanggal tidak tersedia, kembalikan string kosong
                const date = new Date(dateEbook);
                const day = String(date.getDate()).padStart(2, '0'); // Menambahkan leading zero jika perlu
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Menambahkan leading zero pada bulan
                const year = date.getFullYear();
                return `${day}/${month}/${year}`; // Mengembalikan format dd/mm/yyyy
            };
            return {
                campaignType,
                formattedDate
            }
        }
    }
</script>

<style>
    .title {
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 16px;
        /* fallback */
        max-height: 32px;
        /* fallback */
        -webkit-line-clamp: 1;
        /* number of lines to show */
        -webkit-box-orient: vertical;
    }
    .btn-download {
        position: center;
        background-color: #f57c00; /* Orange color */
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        font-size: 0.9em;
    }
    
    .btn-download:hover {
        background-color: #e64a19; /* Darker orange on hover */
    }
</style>